/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
/* eslint-disable no-case-declarations */
/* eslint-disable prefer-destructuring */
import { message } from 'antd';
import Auth0Lock from 'auth0-lock';
import axios from 'axios';
/* import * as crypto from 'crypto-js'; */
import moment from 'moment';
import notification from '@iso/components/Feedback/Notification';
import Auth0Config from '@iso/config/auth0.config';
import firebase from '@iso/lib/firebase/firebase';
import history from '@iso/lib/helpers/history';

const metadata = 'https://api-auth.icancha.com/metadata';
class Backend {
    static instance;
    static lock;
    constructor() {
        console.info('Backend -> Started');
        this.baseURL = `${process.env.REACT_APP_URL}/centros`;
        this.authURL = `${process.env.REACT_APP_URL}/authentication`;
        this.utilitiesURL = `${process.env.REACT_APP_URL}/utilities`;
        this.ready = false;
        this.firebase = firebase;
        this.idToken = '';
        this.accessToken = '';
        this.headers = { Authorization: `Bearer ${this.idToken}` };
        this.actualUserUID = '';
        this.userData = {};
        this.lock = new Auth0Lock(Auth0Config.clientID, Auth0Config.domain, Auth0Config.options);
        this.initializeData();
    }

    static getInstance() {
        if (!Backend.instance) {
            return (Backend.instance = new Backend());
        }
        console.log({ backend: this.instance });
        return Backend.instance;
    }

    /** Funciones para Auth0 */
    login = () => {
        if (!this.lock) {
            return;
        }
        this.lock.show();
    };

    /** Maneja la autenticación */
    handleAuthentication = () => {
        try {
            this.lock.checkSession({}, (error, authResult) => {
                console.log({ error, authResult })
                if (authResult?.idToken) {
                    this.setSession(authResult);
                } else {
                    notification.error({ message: '¡Tu sesión ha expirado!', description: 'La sesión de autenticación ha expirado, serás redirigido al login para que ingreses tus credenciales' });
                    setTimeout(this.logout(), 1000);
                }
            });
            // this.lock.on('authenticated', this.setSession);
            // // Add callback for Lock's `authorization_error` event
            // this.lock.on('authorization_error', error => {
            //     notification.error({ message: '¡Tu sesión ha expirado!', description: 'La sesión de autenticación ha expirado, serás redirigido al login para que ingreses tus credenciales' });
            //     setTimeout(this.logout(), 1000);
            // });
        } catch (error) {
            console.log({ handleAuthentication: error });
            // Add callback Lock's `authenticated` event
        }
    };

    /** Inicializa la sesión luego de verificar el estado de autenticado */
    setSession = authResult => {
        const userMetadata = authResult.idTokenPayload[metadata];
        if (!userMetadata) {
            return this.logout();
        }
        const roleid = userMetadata.roleid;
        switch (roleid) {
            case 1:
                break;
            case 2:
                break;
            case 3:
                break;
            default:
                return this.logout();
        }
        // Setea el tiempo en el que el token expirará
        // const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('expires_at', authResult.expiresIn);
        // Todo el flujo de login ocurrio satisfactoriamente se redirige al dashboard
        history.replace('/dashboard');
    };

    /** Realiza un logout y limpia todas las variables almacenadas */
    logout = () => {
        console.log('se ejecuto logout');
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        document.cookie = '_sso.ic=placeholder; max-age=-1';
        document.cookie = 'sso.ic_=placeholder; max-age=-1';
        // Se limpió toda la data, se redirige al login
        history.replace('/');
        this.lock.logout({
            returnTo: process.env.REACT_APP_REDIRECT
        });
    };

    changeUserPassword = async newPassword => {
        try {
            const destination = `${this.authURL}/usuarios/${this.actualUserUID}/actions/changepassword`;
            const connection = await axios({ method: 'PATCH', url: destination, headers: this.headers, data: { password: newPassword } });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1002:
                    message.info({ content: respuesta });
                    return { cod };
                default:
                    return { cod, data };
            }
        } catch (error) {
            console.log({ changeUserPassword: error });
        }
    };

    /** Funciones para el Backend */
    /** Inicializa los datos requeridos para interactuar con el backend */
    initializeData = () => {
        this.lock.checkSession({}, (error, authResult) => {
            const { href } = window.location;
            if (error) {
                if (error.code === 'login_required') {
                    const inDashboard = href.includes('dashboard');
                    if (inDashboard) {
                        message.error({ content: 'No hemos podido validar tu sesión, serás redirigido al login para que vuelvas a introducir tus credenciales' });
                        setTimeout(this.logout(), 1000);
                    }
                }
                if (process.env.NODE_ENV !== 'production') {
                    console.log({ error });
                }
            } else {
                const {
                    idToken,
                    idTokenPayload: { sub },
                    accessToken
                } = authResult;
                this.accessToken = accessToken;
                this.idToken = idToken;
                if (process.env.NODE_ENV !== 'production') {
                    console.log({ idToken, authResult });
                }
                console.log({ authResult })
                this.actualUserUID = sub;
                this.headers = { Authorization: `Bearer ${this.idToken}` };
                const axiosRequest = axios({ method: 'GET', url: this.baseURL, headers: this.headers });
                axiosRequest
                    .then(connection => {
                        const {
                            data: { admindata, cod, respuesta }
                        } = connection;
                        this.userData = admindata;
                        //console.log({ admindata });
                        this.ready = true;
                        //    console.log({ cod, respuesta, admindata });
                    })
                    .catch(axiosError => {
                        const {
                            response: { data, status }
                        } = axiosError;
                        message.error(data.respuesta);
                        if (status === 401) {
                            message.error(data.respuesta);
                            setTimeout(() => {
                                this.logout();
                            }, 1000);
                        } else {
                            notification.error({ message: 'Hay inconvenientes con la conexión', description: `Si deseas información adicional contactanos e indicanos este código: ${data.cod}` });
                        }
                    });
            }
        });
    };

    getCentroId = () => {
        const rol = this.userData.profile.idadminrol;
        switch (rol) {
            case 2:
                return this.userData.centros[0].idcentro;
            default:
                return this.userData.sedeFrom;
        }
    };

    getCountryISO = () => {
        console.count('getCountryISO');
        return this.userData.countryISO;
    };

    /** Métodos para centro deportivo */

    getCentroInfo = async () => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}`;
            // message.loading({ content: 'Cargando información del centro deportivo...', key: 'process' });
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1003:
                    message.info({ content: respuesta, key: 'process' });
                    return { cod };
                default:
                    // message.success({ content: respuesta, key: 'process', duration: 2 });
                    return { cod, data };
            }
        } catch (error) {
            console.log({ getCentroInfo: error });
        }
    };
    getValueFacture = async () => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/ic-invoices/current-week`;
            // message.loading({ content: 'Cargando información del centro deportivo...', key: 'process' });
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });

            return connection
        } catch (error) {
            console.log({ getCentroInfo: error });
        }
    };

    updateCentroInfo = async (nombre, sitioweb, idciudad, idpais, descripcion, logo, correo) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}`;
            const connection = await axios({ method: 'PATCH', url: destination, headers: this.headers, data: { nombre, sitioweb, idciudad, idpais, descripcion, logo, correo } });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ updateCentroInfo: error });
        }
    };

    getAllSedes = async (search?) => {
        try {
            const centroId = this.getCentroId();
            let destination = `${this.baseURL}/${centroId}/sedes`;
            if (search) {
                destination = `${this.baseURL}/${centroId}/sedes?search=${search}`;
            }
            // message.loading({ content: 'Obteniendo listado de sedes...', key: 'processSede' });
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1003:
                    message.info({ content: respuesta, key: 'processSede' });
                    return { cod };
                default:
                    // message.success({ content: respuesta, key: 'processSede', duration: 2 });
                    return { cod, data };
            }
        } catch (error) {
            console.log({ getAllSedes: error });
        }
    };

    createAdministrator = async ({ apellido, correo, password, sedes, nombre }) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/administrators`;

            //    console.log({ apellido, correo, password, sedes, nombre });
            const connection = await axios({
                method: 'POST',
                url: destination,
                headers: this.headers,
                data: {
                    apellido,
                    correo,
                    password,
                    sedes,
                    nombre
                }
            });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1003:
                    message.info({ content: respuesta });
                    return { cod };
                default:
                    return { cod, data };
            }
        } catch (error) {
            console.log({ createAdministrator: error });
        }
    };

    getAdministrators = async (page?, limit?) => {
        try {
            const centroId = this.getCentroId();
            let destination = `${this.baseURL}/${centroId}/administrators`;
            if (page && limit) {
                destination = `${this.baseURL}/${centroId}/administrators?page=${page}&limit=${limit}`;
            }
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1003:
                    message.info({ content: respuesta });
                    return { cod };
                default:
                    return { cod, data };
            }
        } catch (error) {
            console.log({ getAdministrators: error });
        }
    };

    getAllAdministrators = async (search?) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/administrators?search=${search}`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1003:
                    message.info({ content: respuesta });
                    return { cod };
                default:
                    return { cod, data };
            }
        } catch (error) {
            console.log({ getAllAdministrators: error });
        }
    };

    manageSedeToAdmin = async (sede, idadminTarget, action) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/administrators/${idadminTarget}/actions/sede`;
            const method = action === 1 ? 'POST' : 'DELETE';
            const connection = await axios({ method, url: destination, headers: this.headers, data: { sedeid: sede } });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1003:
                    message.info({ content: respuesta });
                    return { cod };
                default:
                    return { cod, data };
            }
        } catch (error) {
            console.log({ manageSedeToAdmin: error });
        }
    };

    requestPasswordReset = async uidadminTarget => {
        try {
            const destination = `${this.authURL}/usuarios/${uidadminTarget}/actions/recovery`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ requestPasswordReset: error });
        }
    };

    manageAdminStatus = async (idadmintarget, status) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/administrators/${idadmintarget}`;
            const connection = await axios({ method: 'PATCH', url: destination, headers: this.headers, data: { status } });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ manageAdminStatus: error });
        }
    };

    deleteAdministrator = async idadmintarget => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/administrators/${idadmintarget}`;
            const connection = await axios({ method: 'DELETE', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ deleteAdministrator: error });
        }
    };

    getSedes = async (select?, page?, limit?, signal) => {
        try {
            const centroId = this.getCentroId();
            let destination = `${this.baseURL}/${centroId}/sedes`;
            if (select) {
                destination = `${this.baseURL}/${centroId}/sedes?page=1&limit=1000`;
            }
            if (page && limit) {
                destination = `${this.baseURL}/${centroId}/sedes?page=${page}&limit=${limit}`;
            }
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers, cancelToken: signal });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ getSedes: error });
            return { cod: 'canceled' };
        }
    };

    getSedeData = async sedeId => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/sedes/${sedeId}`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ getSedeData: error });
        }
    };

    createSede = async ({ nombre, idciudad, telefono, correo, logoSede, descripcion, servicios, direccion, latitud, longitud }) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/sedes`;
            const connection = await axios({ method: 'POST', url: destination, headers: this.headers, data: { nombre, idciudad, telefono, correo, logoSede, descripcion, servicios, direccion, latitud, longitud } });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ createSede: error });
        }
    };

    updateSede = async ({ nombre, idciudad, telefono, correo, logoSede, descripcion, servicios, direccion, latitud, longitud, sedeid }) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/sedes/${sedeid}`;
            const connection = await axios({ method: 'PATCH', url: destination, headers: this.headers, data: { nombre, idciudad, telefono, correo, logoSede, descripcion, servicios, direccion, latitud, longitud } });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ updateSede: error });
        }
    };

    createFacturacion = async ({ iddocumentoventa, idsistemacontable, prefijo, consecutivo, razonsocial, nit, telefono, direccion, pagaiva, idsede, informacionadicional }) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/sedes/${idsede}/facturas`;
            const connection = await axios({ method: 'POST', url: destination, headers: this.headers, data: { iddocumentoventa, idsistemacontable, prefijo, consecutivo, razonsocial, nit, telefono, direccion, pagaiva, informacionadicional } });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ createFacturacion: error });
        }
    };

    updateFacturacion = async ({ iddocumentoventa, idsistemacontable, prefijo, consecutivo, razonsocial, nit, telefono, direccion, pagaiva, idsede, iddatofactura, informacionadicional }) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/sedes/${idsede}/facturas/${iddatofactura}`;
            const connection = await axios({ method: 'PATCH', url: destination, headers: this.headers, data: { iddocumentoventa, idsistemacontable, prefijo, consecutivo, razonsocial, nit, telefono, direccion, pagaiva, informacionadicional } });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ updateFacturacion: error });
        }
    };

    getFacturacionData = async (sedeId, facturacionId) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/sedes/${sedeId}/facturas/${facturacionId}`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    // message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ getFacturacionData: error });
        }
    };

    disableSede = async sedeId => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/sedes/${sedeId}`;
            const connection = await axios({ method: 'DELETE', url: destination, headers: this.headers, data: { estado: false } });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ disableSede: error });
        }
    };

    getServicios = async () => {
        try {
            const destination = `${this.utilitiesURL}/servicios`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ getServicios: error });
        }
    };

    getSedeFields = async (sedeId, select?, page?, limit?) => {
        try {
            const centroId = this.getCentroId();
            let destination = `${this.baseURL}/${centroId}/canchas?sede=${sedeId}`;
            if (select) {
                destination = `${this.baseURL}/${centroId}/canchas?sede=${sedeId}&page=1&limit=1000`;
            }
            if (page && limit) {
                destination = `${this.baseURL}/${centroId}/canchas?sede=${sedeId}&page=${page}&limit=${limit}`;
            }
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1003:
                    // message.info({ content: respuesta });
                    return { cod };
                default:
                    return { cod, data };
            }
        } catch (error) {
            console.log({ getSedeFields: error });
        }
    };

    getAllCanchas = async (sedeId, search?) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/canchas?sede=${sedeId}&search=${search}`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1003:
                    message.info({ content: respuesta });
                    return { cod };
                default:
                    return { cod, data };
            }
        } catch (error) {
            console.log({ getSedeFields: error });
        }
    };

    getSedeTarifas = async sedeId => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/sedes/${sedeId}/tarifas`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ getSedeTarifas: error });
        }
    };

    getSedeTarifa = async (sedeId, tarifaId) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/sedes/${sedeId}/tarifas/${tarifaId}`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ getSedeTarifa: error });
        }
    };

    createSedeTarifa = async ({ nombre, descripcion, dataTarifa, idsede }) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/sedes/${idsede}/tarifas`;
            const connection = await axios({
                method: 'POST',
                url: destination,
                headers: this.headers,
                data: {
                    nombre,
                    descripcion,
                    dataTarifa: dataTarifa.map(a => {
                        if (a.hora === '24:00:00') {
                            a.hora = '00:00:00';
                        }
                        return a;
                    })
                }
            });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ createSedeTarifa: error });
        }
    };

    updateSedeTarifa = async ({ nombre, descripcion, dataTarifa, idsede, tarifaid }) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/sedes/${idsede}/tarifas/${tarifaid}`;
            const connection = await axios({
                method: 'PATCH',
                url: destination,
                headers: this.headers,
                data: {
                    nombre,
                    descripcion,
                    dataTarifa: dataTarifa.map(a => {
                        if (a.hora === '24:00:00') {
                            a.hora = '00:00:00';
                        }
                        return a;
                    })
                }
            });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ updateSedeTarifa: error });
        }
    };

    /** Métodos para pasaporte deportivo */
    getPasaportes = async () => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/pasaportes`;
            //    message.loading({ content: 'Cargando información...', key: 'process' });
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1003:
                    message.info({ content: respuesta, key: 'process' });
                    return { cod };
                default:
                    //            message.success({ content: respuesta, key: 'process', duration: 2 });
                    return { cod, data };
            }
        } catch (error) {
            console.log({ getPasaportes: error });
        }
    };

    downloadPasaportes = async () => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/pasaportes/actions/download`;
            message.loading({ content: 'Cargando información...', key: 'process' });
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers, responseType: 'blob' });
            const {
                data,
                data: { type }
            } = connection;
            //    console.log({ connection });
            switch (type) {
                case 'application/json':
                    message.info({ content: 'No hay registros por descargar', key: 'process' });
                    return;
                default:
                    message.success({ content: '¡Documento creado!', key: 'process', duration: 2 });
                    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `Reporte-covid-19_${new Date()}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    return;
            }
        } catch (error) {
            console.log({ downloadPasaportes: error });
        }
    };

    setPasaporte = async dataRegistro => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/pasaportes`;
            message.loading({ content: 'Cargando información...', key: 'process' });
            const connection = await axios({
                method: 'POST',
                url: destination,
                headers: this.headers,
                data: {
                    dataRegistro
                }
            });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1002:
                    message.info({ content: respuesta, key: 'process' });
                    return { cod };
                default:
                    message.success({ content: respuesta, key: 'process', duration: 2 });
                    return { cod, data };
            }
        } catch (error) {
            console.log({ setPasaporte: error });
        }
    };

    getDeportes = async () => {
        try {
            const destination = `${this.utilitiesURL}/deportes`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ getDeportes: error });
        }
    };
    getClasificacion = async deporteId => {
        try {
            const destination = `${this.utilitiesURL}/deportes/${deporteId}/clasificacion`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ getClasificacion: error });
        }
    };
    getAtributos = async () => {
        try {
            const destination = `${this.utilitiesURL}/atributos`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ getAtributos: error });
        }
    };

    createCancha = async ({ nombre, idclasificacion, imagenes, atributos, idsede }) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/canchas?idsede=${idsede}`;
            const connection = await axios({ method: 'POST', url: destination, headers: this.headers, data: { nombre, idclasificacion, imagenes, atributos } });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ createCancha: error });
        }
    };
    updateCancha = async ({ nombre, idclasificacion, imagenes, atributos, idsede, canchaId }) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/canchas/${canchaId}?idsede=${idsede}`;
            const connection = await axios({ method: 'PATCH', url: destination, headers: this.headers, data: { nombre, idclasificacion, imagenes, atributos } });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ updateCancha: error });
        }
    };
    addTarifaCancha = async ({ canchaId, tarifa, sede }) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/canchas/${canchaId}/actions/tarifa?sede=${sede}&tarifa=${tarifa}`;
            const connection = await axios({ method: 'POST', url: destination, headers: this.headers, data: {} });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ addTarifaCancha: error });
        }
    };

    getUsuarioDocument = async (documento, tipo) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/usuarios?documento=${documento}&tipo=${tipo}`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1003:
                    return { cod };
                default:
                    return { cod, data };
            }
        } catch (error) {
            console.log({ getUsuarioDocument: error });
        }
    };

    checkDisponibilidadReserva = async ({ canchaId, sedeId, start, end, rsvId }) => {
        try {
            const centroId = this.getCentroId();
            // http://localhost:8080/centros/16/canchas/2/actions/rsvp?sede=11&datetimestart=1600794000&datetimeend=1600797600
            const destination = `${this.baseURL}/${centroId}/canchas/${canchaId}/actions/rsvp?sede=${sedeId}&datetimestart=${start}&datetimeend=${end}${rsvId ? `&historicorsvpId=${rsvId}` : ''}`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1003:
                    return { cod };
                default:
                    return { cod, data };
            }
        } catch (error) {
            console.log({ checkDisponibilidadReserva: error });
        }
    };

    getTarifaHoras = async (canchaId, sedeId, selected, timepicker) => {
        try {
            const centroId = this.getCentroId();
            // http://localhost:8080/centros/16/canchas/2/actions/tarifa?sede=11&fecha=2020-03-23&hora=06
            const fecha = moment(selected[0]).format('YYYY-MM-DD');
            if (timepicker) {
                const allHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
                const horainicio = parseInt(selected[0].format('HH'), 10);
                const horafinal = parseInt(selected[1].format('HH'), 10);
                const horasbuscar = allHours.filter(hour => hour >= horainicio && hour < horafinal).map(hora => `${hora}:00`);
                const requestList = horasbuscar.map(hora => {
                    const destination = `${this.baseURL}/${centroId}/canchas/${canchaId}/actions/tarifa?sede=${sedeId}&fecha=${fecha}&hora=${hora}`;
                    return axios({ method: 'GET', url: destination, headers: this.headers });
                });
                const connection = await Promise.all(requestList);
                const tarifa = connection
                    .filter(respuesta => respuesta.data.cod === 1000)
                    .reduce((total, actual) => {
                        const monto = parseFloat(actual.data.data[0].monto, 10);
                        return monto + total;
                    }, 0);
                return tarifa;
            }
            if (selected.length > 2) {
                const requestList = selected.slice(0, selected.length - 1).map(datetime => {
                    const hora = moment(datetime).format('HH:00');
                    const destination = `${this.baseURL}/${centroId}/canchas/${canchaId}/actions/tarifa?sede=${sedeId}&fecha=${fecha}&hora=${hora}`;
                    return axios({ method: 'GET', url: destination, headers: this.headers });
                });
                const connection = await Promise.all(requestList);
                const tarifa = connection
                    .filter(respuesta => respuesta.data.cod === 1000)
                    .reduce((total, actual) => {
                        const monto = parseFloat(actual.data.data[0].monto, 10);
                        return monto + total;
                    }, 0);
                return tarifa;
            }
            const hora = moment(selected[0]).format('HH:00');
            const destination = `${this.baseURL}/${centroId}/canchas/${canchaId}/actions/tarifa?sede=${sedeId}&fecha=${fecha}&hora=${hora}`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });

            const tarifa = [{ ...connection }]
                .filter(respuesta => respuesta.data.cod === 1000)
                .reduce((total, actual) => {
                    const monto = parseFloat(actual.data.data[0].monto, 10);
                    //               console.log({ monto });
                    return monto + total;
                }, 0);
            return tarifa;
        } catch (error) {
            console.log({ getTarifaHoras: error });
        }
    };

    getReservasOnRange = async (canchaId, sedeId, start, end) => {
        try {
            const centroId = this.getCentroId();
            // http://localhost:8080/centros/16/canchas/2/rsvp?sede=11&start=1600664400&end=1601182800
            const destination = `${this.baseURL}/${centroId}/canchas/${canchaId}/rsvp?sede=${sedeId}&start=${start}&end=${end}`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1003:
                    return { cod };
                default:
                    return { cod, data };
            }
        } catch (error) {
            console.log({ getReservasOnRange: error });
        }
    };

    createUser = async ({ nombre, apellido, correo, telefono, tipodocumento, numerodocumento, direccion }) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/usuarios`;
            const connection = await axios({ method: 'POST', url: destination, headers: this.headers, data: { nombre, apellido, correo, telefono, tipodocumento, numerodocumento, direccion } });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    return { cod };
            }
        } catch (error) {
            console.log({ createUser: error });
        }
    };

    updateUser = async ({ nombre, apellido, correo, telefono, tipodocumento, numerodocumento, direccion, idusuario }) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/usuarios/${idusuario}`;
            const connection = await axios({ method: 'PATCH', url: destination, headers: this.headers, data: { nombre, apellido, correo, telefono, tipodocumento, numerodocumento, direccion } });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ updateUser: error });
        }
    };

    getAllUsers = async (select?, page?, limit?) => {
        try {
            const centroId = this.getCentroId();
            let destination = `${this.baseURL}/${centroId}/usuarios`;
            if (select) {
                destination = `${this.baseURL}/${centroId}/usuarios?page=1&limit=1000`;
            }
            if (page && limit) {
                destination = `${this.baseURL}/${centroId}/usuarios?page=${page}&limit=${limit}`;
            }
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1003:
                    message.info({ content: respuesta, key: 'process' });
                    return { cod };
                default:
                    return { cod, data };
            }
        } catch (error) {
            console.log({ getAllUsers: error });
        }
    };

    getAllUsersName = async (search?) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/usuarios?search=${search}`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1003:
                    message.info({ content: respuesta, key: 'process' });
                    return { cod };
                default:
                    return { cod, data };
            }
        } catch (error) {
            console.log({ getAllUsersName: error });
        }
    };

    createBooking = async ({ idcancha, idsede, timestamprsvp, timestamprsvpend, handlerepeat, repetir, facturar, idusuario, tarifa }) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/canchas/${idcancha}/rsvp?sede=${idsede}`;
            const connection = await axios({ method: 'POST', url: destination, headers: this.headers, data: { timestamprsvp, timestamprsvpend, handlerepeat, repetir, facturar, idusuario, tarifa } });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    return { cod };
            }
        } catch (error) {
            console.log({ createBooking: error });
        }
    };

    updateBooking = async ({ timestamprsvp, timestamprsvpend, idusuario, tarifa, idcancha, idrsvp, idsede, moveToField }) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/canchas/${idcancha}/rsvp/${idrsvp}?sede=${idsede}`;
            const connection = await axios({ method: 'PATCH', url: destination, headers: this.headers, data: { timestamprsvp, timestamprsvpend, idusuario, tarifa, moveToField } });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    return { cod };
            }
        } catch (error) {
            console.log({ updateBooking: error });
        }
    };

    cancelBooking = async (idcancha, idsede, idrsvp) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/canchas/${idcancha}/rsvp/${idrsvp}?sede=${idsede}`;
            const connection = await axios({ method: 'DELETE', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    return { cod };
            }
        } catch (error) {
            console.log({ cancelBooking: error });
        }
    };

    setRsvpPayments = async ({ idcancha, idsede, idrsvp, payments, planName }) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/canchas/${idcancha}/rsvp/${idrsvp}/actions/payments?sede=${idsede}`;
            const connection = await axios({ method: 'POST', url: destination, headers: this.headers, data: { payments, planName } });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    return { cod };
            }
        } catch (error) {
            console.log({ setRsvpPayments: error });
        }
    };

    getRsvpPayments = async ({ idcancha, idsede, idrsvp }) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/canchas/${idcancha}/rsvp/${idrsvp}/actions/payments?sede=${idsede}`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    return { cod };
            }
        } catch (error) {
            console.log({ getRsvpPayments: error });
        }
    };

    getReservasOnRangeSede = async (sedeId, start, end) => {
        try {
            const centroId = this.getCentroId();
            // http://localhost:8080/centros/16/sedes/11/rsvp?start=1600664400&end=1601182800
            const destination = `${this.baseURL}/${centroId}/sedes/${sedeId}/rsvp?start=${start}&end=${end}`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1003:
                    return { cod };
                default:
                    return { cod, data };
            }
        } catch (error) {
            console.log({ getReservasOnRangeSede: error });
        }
    };

    manageFieldNesting = async ({ idcancha, idsede, nestedfields }) => {
        // http://localhost:8080/centros/16/canchas/2/actions/nest?sede=1
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/canchas/${idcancha}/actions/nest?sede=${idsede}`;
            const connection = await axios({ method: 'POST', url: destination, headers: this.headers, data: { nestedfields } });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    return { cod };
            }
        } catch (error) {
            console.log({ manageFieldNesting: error });
        }
    };

    /** Módulo de reportes */
    getReportDataForDisplay = async ({ idsede, start, end, signal }) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/sedes/${idsede}/reports?start=${start}&end=${end}`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers, cancelToken: signal });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    return { cod };
            }
        } catch (error) {
            console.log({ getReportDataForDisplay: error });
            return { cod: 'canceled' };
        }
    };

    downloadReportPlayedRsvp = async ({ idsede, start, end, signal }) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/sedes/${idsede}/reports/actions/download/playedrsvp?start=${start}&end=${end}`;
            message.loading({ content: 'Cargando información...', key: 'process' });
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers, responseType: 'blob', cancelToken: signal });
            const {
                data,
                data: { type }
            } = connection;
            //    console.log({ connection });
            switch (type) {
                case 'application/json':
                    message.info({ content: 'No hay registros por descargar', key: 'process' });
                    return;
                default:
                    message.success({ content: '¡Documento creado!', key: 'process', duration: 2 });
                    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `Played_rsvp${new Date()}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    return;
            }
        } catch (error) {
            console.log({ downloadReportPlayedRsvp: error });
            return { cod: 'canceled' };
        }
    };

    downloadReportCreatedRsvp = async ({ idsede, start, end, signal }) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/sedes/${idsede}/reports/actions/download/createdrsvp?start=${start}&end=${end}`;
            message.loading({ content: 'Cargando información...', key: 'process' });
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers, responseType: 'blob', cancelToken: signal });
            const {
                data,
                data: { type }
            } = connection;
            //    console.log({ connection });
            switch (type) {
                case 'application/json':
                    message.info({ content: 'No hay registros por descargar', key: 'process' });
                    return;
                default:
                    message.success({ content: '¡Documento creado!', key: 'process', duration: 2 });
                    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `Created_rsvp${new Date()}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    return;
            }
        } catch (error) {
            console.log({ downloadReportCreatedRsvp: error });
            return { cod: 'canceled' };
        }
    };

    downloadReportCanceledRsvp = async ({ idsede, start, end, signal }) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/sedes/${idsede}/reports/actions/download/canceledrsvp?start=${start}&end=${end}`;
            message.loading({ content: 'Cargando información...', key: 'process' });
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers, responseType: 'blob', cancelToken: signal });
            const {
                data,
                data: { type }
            } = connection;
            //    console.log({ connection });
            switch (type) {
                case 'application/json':
                    message.info({ content: 'No hay registros por descargar', key: 'process' });
                    return;
                default:
                    message.success({ content: '¡Documento creado!', key: 'process', duration: 2 });
                    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `Canceled_rsvp${new Date()}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    return;
            }
        } catch (error) {
            console.log({ downloadReportCanceledRsvp: error });
            return { cod: 'canceled' };
        }
    };

    downloadReportPayments = async ({ idsede, start, end, signal }) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/sedes/${idsede}/reports/actions/download/payments?start=${start}&end=${end}`;
            message.loading({ content: 'Cargando información...', key: 'process' });
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers, responseType: 'blob', cancelToken: signal });
            const {
                data,
                data: { type }
            } = connection;
            //    console.log({ connection });
            switch (type) {
                case 'application/json':
                    message.info({ content: 'No hay registros por descargar', key: 'process' });
                    return;
                default:
                    message.success({ content: '¡Documento creado!', key: 'process', duration: 2 });
                    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `Payments${new Date()}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    return;
            }
        } catch (error) {
            console.log({ downloadReportPayments: error });
            return { cod: 'canceled' };
        }
    };

    getCountries = async () => {
        try {
            const destination = `${this.utilitiesURL}/paises`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ getCountries: error });
        }
    };

    getCities = async () => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.utilitiesURL}/ciudades?centro=${centroId}`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ getCities: error });
        }
    };

    getDocuments = async () => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.utilitiesURL}/documentos?centro=${centroId}`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ getDocuments: error });
        }
    };

    getPaymethods = async () => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.utilitiesURL}/paymethods?centro=${centroId}`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ getDocuments: error });
        }
    };

    getPaymentsLog = async (sedeid, dateselected) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/logs/payments?sedeid=${sedeid}&dateselected=${dateselected}`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ getPaymentsLog: error });
        }
    };

    getRsvpLog = async (sedeid, dateselected) => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/logs/rsvp?sedeid=${sedeid}&dateselected=${dateselected}`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ getRsvpLog: error });
        }
    };

    /* obtener tipo de documento de venta */
    getDocumentsVenta = async () => {
        try {
            const destination = `${this.utilitiesURL}/documentosventas`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ getDocumentsVenta: error });
        }
    };

    /* obtener tipo de sistema contable */
    getSistemaContable = async idpagodetalle => {
        try {
            const destination = `${this.utilitiesURL}/sistemacontable`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ getSistemaContable: error });
        }
    };
    /* Obtener datos para la factura */
    getInfofactura = async idpagodetalle => {
        try {
            const destination = `${this.utilitiesURL}/infopdf/${idpagodetalle}`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ getInfofactura: error });
        }
    };

    getAllComprobantesIC = async () => {
        try {
            // Se prueba con el ID 46
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/ic-invoices/`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ getAllComprobantesIC: error });
        }
    };

    downloadXLSIC = async idfacturasede => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/ic-invoices/${idfacturasede}/xls`;
            message.loading({ content: 'Cargando información...', key: 'process' });
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers, responseType: 'blob' });
            const {
                data,
                data: { type }
            } = connection;
            switch (type) {
                case 'application/json':
                    message.info({ content: 'No hay registros por descargar', key: 'process' });
                    return;
                default:
                    message.success({ content: '¡Documento creado!', key: 'process', duration: 2 });
                    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `WeeklyReport${new Date()}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    return;
            }
        } catch (error) {
            console.log({ downloadXLSIC: error });
            return { cod: 'canceled' };
        }
    };

    downloadPDFIC = async idfacturasede => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/ic-invoices/${idfacturasede}/pdf`;
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ getAllComprobantesIC: error });
        }
    };

    getInfPagosSede = async (sedeid, search?, { start, end }) => {
        try {
            const centroId = this.getCentroId();
            let destination = `${this.baseURL}/${centroId}/infPaymentsSede?sedeid=${sedeid}&start=${start}&end=${end}`;
            if (search) {
                destination = `${this.baseURL}/${centroId}/infPaymentsSede?sedeid=${sedeid}&search=${search}`;
            }
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1000:
                    return { cod, data };
                default:
                    message.info({ content: respuesta });
                    return { cod };
            }
        } catch (error) {
            console.log({ getInfPagosSede: error });
        }
    };

    /*

    getPasaportes = async () => {
        try {
            const centroId = this.getCentroId();
            const destination = `${this.baseURL}/${centroId}/pasaportes`;
            //    message.loading({ content: 'Cargando información...', key: 'process' });
            const connection = await axios({ method: 'GET', url: destination, headers: this.headers });
            const {
                data: { data, cod, respuesta }
            } = connection;
            switch (cod) {
                case 1003:
                    message.info({ content: respuesta, key: 'process' });
                    return { cod };
                default:
                    //            message.success({ content: respuesta, key: 'process', duration: 2 });
                    return { cod, data };
            }
        } catch (error) {
            console.log({ getPasaportes: error });
        }
    };
    */
}

export default Backend;
