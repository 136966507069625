import { EventEmitter } from 'events';
import Auth0Lock from 'auth0-lock';
import history from '@iso/lib/helpers/history';
import Auth0Config from '@iso/config/auth0.config';
import notification from '@iso/components/Feedback/Notification';
import * as crypto from 'crypto-js';

export const metadata = 'https://api-auth.icancha.com/metadata';
class Auth0 extends EventEmitter {
    lock = new Auth0Lock(Auth0Config.clientID, Auth0Config.domain, Auth0Config.options);

    login = () => {
        if (!this.lock) {
            return;
        }
        this.lock.show();
    };

    handleAuthentication = () => {
        // Add callback Lock's `authenticated` event
        this.lock.on('authenticated', this.setSession);
        // Add callback for Lock's `authorization_error` event
        this.lock.on('authorization_error', (error) => {
            notification.error({ message: '¡Tu sesión ha expirado!', description: 'La sesión de autenticación ha expirado, serás redirigido al login para que ingreses tus credenciales' });
            setTimeout(this.logout(), 1000);
        });
    };

    /** Inicializa la sesión luego de verificar el estado de autenticado */
    setSession = (authResult) => {
        const userMetadata = authResult.idTokenPayload[metadata];
        if (!userMetadata) {
            return this.logout();
        }
        const roleid = userMetadata.roleid;
        switch (roleid) {
            case 1:
                break;
            case 2:
                break;
            case 3:
                break;
            default:
                return this.logout();
        }
        // Setea el tiempo en el que el token expirará
        const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
        localStorage.setItem('access_token', crypto.AES.encrypt(authResult.accessToken, authResult.idTokenPayload.sub).toString());
        localStorage.setItem('id_token', crypto.AES.encrypt(authResult.idToken, authResult.idTokenPayload.sub).toString());
        localStorage.setItem('expires_at', expiresAt);
        // Todo el flujo de login ocurrio satisfactoriamente se redirige al dashboard
        history.replace('/dashboard');
    };

    /** Realiza un logout y limpia todas las variables almacenadas */
    logout = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        document.cookie = '_sso.ic=placeholder; max-age=-1';
        document.cookie = 'sso.ic_=placeholder; max-age=-1';
        // Se limpió toda la data, se redirige al login
        history.replace('/');
        this.lock.logout({
            returnTo: process.env.REACT_APP_REDIRECT,
        });
    };

    isAuthenticated = () => {
        let expiresAt = JSON.parse(localStorage.getItem('expiresAt'));
        return new Date().getTime() < expiresAt;
    };
}
export default Auth0;
