export default {
    apiKey: 'AIzaSyCWKrUv_W_LsQrDxAf_bfKsDF2l7O3_udA',
    authDomain: 'icancha-co.firebaseapp.com',
    databaseURL: 'https://icancha-co.firebaseio.com',
    projectId: 'icancha-co',
    storageBucket: 'icancha-co.appspot.com',
    messagingSenderId: '951928288155',
    appId: '1:951928288155:web:ee1f54d8b77c75aaa3273e',
    appName: 'SARi',
};
