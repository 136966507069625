/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const BackendContext = React.createContext();

const { Consumer } = BackendContext;

export const withBackend = (Component) => (props) => <Consumer>{(backend) => <Component {...props} backend={backend} />}</Consumer>;

export default BackendContext;
